<template>
    <content-title link_field="技术"></content-title>
    <article-list  nav_title="技术" :article_class="17"></article-list>
</template>

<script>
import ContentTitle from "../components/public/ContentTitle";
import ArticleList from "../components/article/ArticleList";
import ArticleNav from "../components/public/ArticleNav";

export default {
    name: "Skills",
    components: {
        ContentTitle,
        ArticleList,
        ArticleNav,
    },
}
</script>

<style scoped>

</style>
